import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import Breadcrumbs from '../../atoms/Breadcrumbs';
import SVG from '../../atoms/SVG';
import LinkStandard from '../../atoms/LinkStandard';
import Styled from './index.styled';

type BreadcrumbTypes = {
	id: string;
	url: string;
	title: string;
	dataGAAction: string;
	dataGALabel: string;
	dataGACategory: string;
};

type LinkTypes = {
	id: string;
	url: string;
	title: string;
	dataGACategory: string;
	dataGAAction: string;
	dataGALabel: string;
};

export type SvgProps = {
	responsiveImage: boolean;
};

export type LinksProps = {
	linksStacked: boolean;
	type?: string;
};

type HeroProps = {
	title: string;
	type?: string;
	paragraph: string;
	para2?: string;
	para3?: string;
	breadcrumbs: Array<BreadcrumbTypes>;
	svg: string;
	linksTitle?: string;
	linksStacked: boolean;
	links?: Array<LinkTypes>;
	responsiveImage?: boolean;
	anchorId?: string;
};

const renderLinkItem = (link: LinkTypes) => (
	<Styled.Hero__LinkItem key={link.id}>
		<LinkStandard {...link} />
	</Styled.Hero__LinkItem>
);

const HeroSvg = ({
	title,
	type,
	paragraph,
	para2,
	para3,
	breadcrumbs,
	svg,
	linksTitle,
	linksStacked,
	links,
	responsiveImage = true,
	anchorId,
}: HeroProps) => {
	const theme = useContext(ThemeContext) || {};
	const fill = theme.primaryAccent;

	return (
		<>
			<Styled.Hero id={anchorId || ''}>
				<Styled.Hero__inner>
					<Styled.Hero__ResponsiveContainer type={type}>
						<Breadcrumbs breadcrumbs={breadcrumbs} isHero={false} />

						<Styled.Hero__TitleSecondaryHero as="h1">
							{title}
						</Styled.Hero__TitleSecondaryHero>

						<Styled.Hero__innerCopy>{paragraph}</Styled.Hero__innerCopy>
						{para2 && <Styled.Hero__innerCopy as="p">{para2}</Styled.Hero__innerCopy>}
						{para3 && <Styled.Hero__innerCopy as="p">{para3}</Styled.Hero__innerCopy>}

						{links && (
							<>
								{linksTitle && (
									<Styled.Hero__BodyHeading>
										{linksTitle}
									</Styled.Hero__BodyHeading>
								)}

								<Styled.Hero__LinkList linksStacked={linksStacked} type={type}>
									{links.map(renderLinkItem)}
								</Styled.Hero__LinkList>
							</>
						)}
					</Styled.Hero__ResponsiveContainer>

					<Styled.Hero__svgContainer type={type} responsiveImage={responsiveImage}>
						<Styled.Hero__svgContainerInner type={type}>
							<SVG name={svg} fill={fill} aria-hidden="true" />
						</Styled.Hero__svgContainerInner>
					</Styled.Hero__svgContainer>
				</Styled.Hero__inner>
			</Styled.Hero>
		</>
	);
};

export default HeroSvg;
